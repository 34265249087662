.app__navbar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 60vh;
  position: relative;
  padding-top: 20px;
  overflow: hidden;
}

.app__navbar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../../assets/navbar_bg.webp') no-repeat center/cover;
  filter: blur(2px);
  z-index: -2;
}

.app__navbar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  mix-blend-mode: darken;
  z-index: -1;
}

ul {
  padding-left: 0;
  user-select: none;
  list-style: none;
}

.app__navbar-smallscreen_links li a:hover {
  text-decoration: underline;
  opacity: 0.8;
  color: white;
  background-color: black;
  border-radius: 30px;
  padding: 1rem;
}

.app__navbar-h1 {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 600;
  letter-spacing: 0.03em;
  font-size: 2rem;
  flex: 1;
  text-align: left;
  z-index: 1;
  white-space: nowrap;
  margin: 2rem 4rem 4rem 4rem;
}

.app__navbar-h2 {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-size: 50px;
  font-weight: 400;
  align-self: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}


.info-button {
  font-family: var(--font-alt);
  color: var(--color-black);
  background-color: var(--color-white);
  font-size: 1rem;
  font-weight: 700;
  padding: 10px 20px;
  margin-top: 1rem;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: calc(50% + 60px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  transition: background-color 0.3s ease;
}

.info-button:hover {
  background-color: #f2f2f2;
}

.hamburgerMenu,
.homeMenu {
  color: var(--color-white);
  font-size: 40px;
  margin: 2rem 4rem 4rem 4rem;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 0px;
}

.homeMenu {
  color: var(--color-black)
}

.app__navbar-smallscreen {
  display: flex;
  align-items: center;
}

.app__navbar-smallscreen_overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 20%;
  height: 60vh;
  background: var(--color-white);
  border-bottom-left-radius: 30px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  box-sizing: border-box;
}

.app__navbar-smallscreen_links {
  list-style: none;
  width: 100%;
  padding: 0;
  margin-top: 2rem;
}

.app__navbar-smallscreen_links a {
  text-decoration: none !important;
  display: block;
  margin-top: 5rem;
  cursor: pointer;
  color: var(--color-black);
  font-size: 1rem;
  text-align: left;
  font-family: var(--font-alt);
}

.app__navbar-smallscreen_links li:hover {
  color: var(--color-white);
}

@media screen and (max-width: 1150px) {
  .app__navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .app__navbar-h1 {
    position: static;
    text-align: left;
    padding-top: 0;
    margin: 1rem;
  }

  .app__navbar-h2 {
    font-size: 35px;
    padding-bottom: 55px;
    white-space: nowrap;
  }

  .hamburgerMenu,
  .homeMenu {
    margin: 2rem
  }

  .app__navbar-smallscreen_overlay {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .app__navbar-smallscreen_links a {
    margin-top: 4rem;
    font-size: 2rem;
  }
}

@media screen and (max-width: 650px) {

  .hamburgerMenu,
  .homeMenu {
    font-size: 30px;
  }

  .app__navbar-h2 {
    padding-bottom: 50px;
    width: 95%;
    white-space: pre-wrap;
    align-self: center;
    margin-left: 15%;
  }

  .app__navbar-h1 {
    font-size: 1.5rem;
    margin-left: 10px;
  }
}