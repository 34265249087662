.app__footer-links_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer_contact_method {
  display: flex;
  flex-direction: row;
}

.section__padding_footer {
  padding: 0;
  height: auto;
}

.app__footer-links_icons svg {
  color: rgb(223, 223, 223);
  font-size: 30px;
  cursor: pointer;
  font-family: var(--font-alt);
  margin-left: 15px;
}

.app__footer-links_icons svg:hover {
  color: rgb(223, 223, 223);
}

.footer__copyright {
  font-family: var(--font-base);
  color: rgb(223, 223, 223);
  white-space: nowrap;
  margin: 10px 0 0 0;
  display: flex;
  justify-content: flex-start;
  /* Alinea el contenido a la izquierda */
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
}

.footer_copyright_paragraph {
  margin: 0px;
  color: rgb(223, 223, 223);
}

.footer_igCta {
  color: rgb(223, 223, 223);
}