.about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(30, 30, 30);
  /* Color de fondo */
  color: #fff;
  /* Color del texto */
  font-family: 'Arial', sans-serif;
  /* Tipo de letra, cambia a tu preferencia */
  padding: 50px;
  /* Padding considerable */
}

.about-text {
  flex-basis: 40%;
  text-align: justify;
  font-size: 1.5rem;
  max-width: 40%;
  hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
}

.about-image {
  object-fit: fill;
  width: 400px !important;
  height: 400px !important;
  flex-basis: 50%;
  /* Ocupa el 50% del contenedor */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  /* Agrega distancia entre la foto y el texto */
}

.about-image-size {
  width: auto !important;
  height: 400px !important;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
  }

  .about-text {
    flex-basis: 100%;
    max-width: 100%;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .about-image {
    flex-basis: 100%;
    margin-left: 0;
  }

  .about-image-size {
    width: auto !important;
    height: 300px !important;
  }
}