.services_bg {
  background-color: rgb(223, 223, 223);
}

.container-cards {
  width: 100%;
  display: flex;
  justify-self: center;
}

.section__padding_cards {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.services-card {
  width: 30%;
  margin: 20px;
  border-radius: 30px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  cursor: default;
  transition: all 400ms ease;
}

.app__services-h1 {
  display: flex;
  font-family: var(--font-alt);
  color: var(--color-petroleo);
  font-weight: 600;
  letter-spacing: 0.03em;
  font-size: 3rem;
  text-align: center !important;
  justify-content: center;
  padding: 2rem;
}

.services-card:hover {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
  transform: translateY(-3%);
  background-color: var(--color-white);
  font-weight: 600;
}

.services-card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 5rem;
}

.services-card h1 {
  font-family: var(--font-alt);
  color: var(--color-petroleo);
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

.services-card ul {
  font-family: var(--font-alt);
  font-size: 1rem;
  padding: 20px;
  list-style-type: circle;
  margin: 0;
}

.services-card li {
  margin: 0 1rem;
  line-height: 1.5rem;
  word-break: break-word;
}

@media screen and (max-width: 1100px) {
  .container-cards {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .services-card {
    width: 40%;
    margin: 20px;
  }

  .app__services-h1 {
    font-size: 40px;
    font-weight: 300;
  }
}

@media screen and (max-width: 650px) {
  .container-cards {
    flex-direction: column;
    align-items: center;
  }

  .services-card {
    width: 70%;
    margin: 30px auto;
  }

  .app__services-h1 {
    font-size: 35px;
  }

}