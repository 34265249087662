.app__findUs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.findUsBg {
  position: relative;
}

.flex__center__findUs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.findUsBg::before {
  content: "";
  background: url('../../assets/findUsBgDesk2.webp') no-repeat center/cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 115%;
  filter: blur(2px);
  z-index: -1;
}

.findUsBg::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 115%;
  background-color: rgba(0, 0, 0, 0.5);
  mix-blend-mode: darken;
  z-index: -1;
}

.findUs-title {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 400;
  line-height: 70px;
  font-size: 45px;
  margin: 10px;
  z-index: 0;
  white-space: pre-wrap;
  margin-right: 40px;
  align-self: start;
}

.btn-danger-intern {
  position: absolute !important;
  left: 5% !important;
  bottom: 75% !important;
  border-radius: 50% !important;
  background-color: rgba(60, 70, 255);
  font-size: 15px !important;
  padding: 10px;
  font-weight: 300 !important;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-danger-intern:hover {
  background-color: red !important;
}

.form_container {
  background-color: rgba(255, 255, 255, 0.6);
  padding: 2rem;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  width: 70%;
  border-radius: 30px;
  z-index: 3;
}

.findUs-form input {
  border: 2px solid black;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 70%;
  height: 3rem;
}

.findUs-sendImgContainer {
  width: 70% !important;
}

.findUs-form-Select {
  border: 2px solid black;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 70%;
  height: 3rem;
}

.findUs-form_button_container {
  right: 0%;
  top: 100%;
}

.findUs-form button {
  font-family: var(--font-alt);
  color: var(--color-black);
  font-size: 1rem;
  font-weight: 700;
  background-color: var(--color-white);
  border-radius: 30px;
  padding: 10px 20px;
  border: none;
  outline: none;
  cursor: pointer;
  max-width: fit-content;
  white-space: nowrap;
  position: static;
  /* Anula la posición absoluta */
  width: auto;
  /* Define cualquier otra propiedad necesaria */
  height: auto;
  margin-top: 20px;
}

.app__findUs-cat {
  font-family: var(--font-alt);
  color: var(--color-petroleo);
  font-weight: 1000;
  letter-spacing: 0.03em;
  font-size: 1rem;
  margin: 10px;
  white-space: nowrap;
}

.findUs-logos {
  position: absolute;
  left: 0px;
  margin: 1rem;
  padding: 5rem
}

img {
  width: 115px !important;
  height: 100px !important;
  margin: 1rem;
}

.file-upload-button {
  background-color: #0C0C0C99;
  color: var(--color-white);
  font-size: 1rem;
  font-weight: 600;
  padding: 10px 20px;
  /* Ajustar según sea necesario */
  border-radius: 30px;
  cursor: pointer;
}

.file-upload-input {
  position: absolute;
  /* Posición absoluta para ocultar el input */
  top: 0;
  left: 0;
  opacity: 0;
  /* Hacer el input transparente */
  width: 100%;
  height: 100%;
  cursor: pointer;
  /* Cambiar el cursor al pasar sobre él */
}

.errorContainer {
  display: flex;
  width: 50%;
  justify-content: left;
}

.errorFindUsForm {
  color: red;
}

.add-photo-container {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 20px;
}

.add-photo-button {
  position: relative;
  bottom: 3px;
  left: 10px;
  font-size: 100px;
  height: 35px;
  width: 35px;
}

.loading-indicator {
  font-size: 50px;
  font-family: var(--font-base);
  color: var(--color-black);
}

.overlay-loading-indicator {
  background-color: rgba(255, 255, 255, 0.6);
  margin-top: 3rem;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 30px;
}

@media screen and (min-width: 1800px) {
  .findUs-text-back {
    margin: 0;
    border-radius: 30px;
  }
}

@media screen and (max-width: 1100px) {
  .flex__center__findUs {
    display: grid;
  }

  .form_container {
    width: 100%;
  }

  .findUs-form input {
    width: 95%;
  }

  .findUs-form-Select {
    width: 95%;
  }

  .errorContainer {
    display: flex;
    width: 100%;
    justify-content: left;
  }

  .findUs-sendImgContainer {
    width: 80% !important;
    margin-top: 2rem;
  }

  .swal2-popup {
    font-size: 1rem;
    width: 200% !important;
  }

  .findUs-title {
    line-height: 40px;
    margin: 0;
    padding: 0px;
    margin-bottom: 30px;
    font-size: 40px;
    font-weight: 300;
    align-self: center;
    white-space: wrap;
    width: 100%;
  }

  .findUs-logos {
    position: relative;
    padding: 0rem;
    margin: 0rem
  }
}

@media screen and (max-width: 650px) {
  .findUs-text-back {
    padding: 0.5rem;
  }

  .findUs-title {
    font-size: 30px;
  }

  .findUs-text {
    font-size: 17px !important;
    font-weight: 400 !important;
    letter-spacing: 0px !important;
    text-align: justify;
    line-height: 30px !important;
    padding: 1rem;
    word-break: break-all !important;

  }

  .app__findUs-h1 {
    font-weight: 450;
    line-height: 20px;
    font-size: 2rem;
    text-align: center !important;
    padding-top: 1rem;
  }

  .add-photo-container {
    position: relative;
    top: 2px;
    left: -20px;
    width: 100%;
    margin-top: 0;
  }

  .add-photo-button {
    position: absolute;
    left: 100%;
    margin-left: 10px;
  }

  .findUs-form_button_container {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 1rem;
    margin-left: 0;
  }

  .findUs-form button {
    width: auto;
    height: auto;
  }

  .btn-danger-intern {
    font-size: 0.5em !important;
    padding: 2em !important;
  }
}

@media screen and (max-width: 450px) {
  .app__findUs-content {
    margin: 0
  }

  .app__findUs-h1 {
    font-size: 15px;
    font-weight: 1000;
  }
}