.App {
  font-family: sans-serif;
  text-align: center;
}


#canvas1 {
  border: 1px solid #2980b9;
  background: red;
}

#canvas2 {
  border: 1px solid #2980b9;
  display: none;
}

#original {
  border: 1px solid #2980b9;
}

#logo {
  border: 1px solid #2980b9;
}

td {
  text-align: center;
}

.square {
  position: relative;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
  float: left;
}

.content_img {
  position: relative;
  width: 100%;
  /* Adjust width as necessary */
  height: auto;
  background-color: transparent;
  border: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}


.photo-container-size {
  width: 100%;
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  gap: 0.5rem;
  margin-top: 2rem;
}

.img-responsive {
  flex: 0 0 auto;
  margin: 5px;
  height: 10rem !important;
  width: 10rem !important;
  object-fit: cover;
  align-self: center;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-zoom {
  cursor: zoom-in;
}

.imgMarcaAguaExample {
  max-width: 45%;
  height: auto;
}

.imgRespondive {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.z_index_1 {
  z-index: 1;
}

.z_index_2 {
  z-index: 2;
}

.z_index_3 {
  z-index: 3;
}

.z_index_4 {
  z-index: 4;
}

.nombreAlbum {
  color: white;
  box-shadow: inset 0 -10px 25px 2px #000000;
  height: 1.7rem;
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
  font-weight: bold;
  /*box-shadow: inset 0 3px 8px rgba(0,0,0,.4);*/
}